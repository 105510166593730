@import '~antd/dist/antd.less';

@font-face {
  font-family: 'america extended';
  src: url('./assets/fonts/gt-america-extended-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/gt-america-extended-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'america extended';
  src: url('./assets/fonts/gt-america-extended-medium-webfont.woff2') format('woff2'),
       url('./assets/fonts/gt-america-extended-medium-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'america standard';
  src: url('./assets/fonts/gt-america-standard-regular-webfont.woff2') format('woff2'),
       url('./assets/fonts/gt-america-standard-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'america standard';
  src: url('./assets/fonts/gt-america-standard-bold-webfont.woff2') format('woff2'),
       url('./assets/fonts/gt-america-standard-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'america standard', "Helvetica Neue", Arial, Helvetica, sans-serif;
  letter-spacing: -0.02512em;
  text-rendering: optimizeLegibility;
}

.App {
  text-align: center;
  min-height: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #7E5CDF;